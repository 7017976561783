<template>
  <g>
    <svg:style>
      .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-3 {
        fill: url(#inox-gradient-2);
      }

      .cls-4 {
        fill: url(#inox-gradient-3);
      }

      .cls-5 {
        fill: url(#inox-gradient-4);
      }

      .cls-6 {
        fill: url(#inox-gradient-5);
      }

      .cls-7 {
        fill: url(#inox-gradient-6);
      }

    </svg:style>
    <g id="L04">
      <linearGradient id="inox-gradient"
        x1="70.99"
        y1="146.86"
        x2="70.99"
        y2="144.74"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stop-color="#babbbc"/>
        <stop offset="0.29" stop-color="#989a9e"/>
        <stop offset="0.65" stop-color="#bdbdc1"/>
        <stop offset="0.87" stop-color="#9d9fa2"/>
        <stop offset="1" stop-color="#a6a8aa"/>
        <stop offset="1" stop-color="#c2c5c9"/>
      </linearGradient>
      <linearGradient id="inox-gradient-2"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>
      <linearGradient id="inox-gradient-3"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>
      <linearGradient id="inox-gradient-4"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>
      <linearGradient id="inox-gradient-5"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>
      <linearGradient id="inox-gradient-6"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>
      <linearGradient id="inox-gradient-7"
        data-name="inox-gradient"
        :x1="inox1X"
        :y1="inox1Y"
        :x2="inox1X + inoxWidth"
        :y2="inox1Y + 2.13"
        xlink:href="#inox-gradient"/>

      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="inox1X"
            :y="inox1Y"
            :width="inoxWidth"
            height="2.12"/>
      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :x="inox1X"
            :y="inox2Y"
            :width="inoxWidth"
            height="2.12"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="inox1X"
            :y="inox3Y"
            :width="inoxWidth"
            height="2.12"/>
      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :x="inox1X"
            :y="inox4Y"
            :width="inoxWidth"
            height="2.13"/>
      <rect id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="inox1X"
            :y="inox5Y"
            :width="inoxWidth"
            height="2.13"/>
      <rect id="inox-6"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :x="inox1X"
            :y="inox6Y"
            :width="inoxWidth"
            height="2.13"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inox2Left: 22,
      leaf2Left: 7,
    }
  },
  computed: {
    inox1X() {
      return this.showBg ?
        this.doorLeftWidth + this.inox2Left :
        this.doorLeftWidth + this.leaf2Left
    },
    inox1Y() {
      return this.doorTopHeight + this.doorHeight * .27 + 2
    },
    inox2Y() {
      return this.doorTopHeight + this.doorHeight * .3 + 2
    },
    inox3Y() {
      return this.doorTopHeight + this.doorHeight * .485 + 2
    },
    inox4Y() {
      return this.doorTopHeight + this.doorHeight * .515 + 2
    },
    inox5Y() {
      return this.doorTopHeight + this.doorHeight * .7 + 2
    },
    inox6Y() {
      return this.doorTopHeight + this.doorHeight * .73 + 2
    },
    inoxWidth() {
      return this.showBg ?
        this.doorWidth - this.inox2Left * 2 :
        this.doorWidth - this.leaf2Left * 2
    },
  }
}
</script>
